import { useContext } from 'react';

import { LoungeProductContext } from './context';

export const useLoungeProductContext = () => {
  const context = useContext(LoungeProductContext);

  if (context === undefined) {
    throw new Error('useLoungeProductContext() must be used within LoungeProductContext.Provider');
  }

  return context;
};

export { LoungeProductContextProvider } from './Provider';
