import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import { createContext } from 'react';

export interface LoungeProductState {
  data: SearchItem | null;
  loading: boolean;
  error: Error | null;
}

export interface LoungeProductContextType extends LoungeProductState {
  loadLoungeProduct: (
    productId: string,
    locale: string,
    travellersCount?: { adults?: number; children?: number; infants?: number },
    entryDate?: string,
    airportCoordinates?: ElementsSDK.Coordinates,
  ) => void;
}

export const LoungeProductContext = createContext<LoungeProductContextType | undefined>(undefined);
