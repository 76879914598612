import { useContext } from 'react';

import { CurrentProductContext } from './context';

export const useCurrentProductContext = () => {
  const context = useContext(CurrentProductContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentProductContext() must be used within CurrentProductContext.Provider',
    );
  }

  return context;
};

export { CurrentProductContextProvider } from './Provider';
