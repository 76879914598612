import React from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentInputContextProvider } from 'context';

import { PageHeading } from 'components';
import { SummaryLayout } from 'components/Layout';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import { PaymentInformation } from 'components/PaymentInformation';
import ProgressBar from 'components/ProgressBar';

import { AirportTransferProductSummary } from 'modules/airport-transfer/components';

import { usePaymentInputPage } from './hooks';
import './styles.scss';

const PaymentInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { isPageReady, paymentInformationFormProps, airportTransferProductSummaryProps } =
    usePaymentInputPage();

  return (
    <div className="airport-transfer-pip">
      {isPageReady ? (
        <PaymentInputContextProvider>
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                <ProgressBar />
                <PageHeading text={t('Header - Payment')} />
                {paymentInformationFormProps && (
                  <PaymentInformation {...paymentInformationFormProps} />
                )}
              </div>
              {airportTransferProductSummaryProps && (
                <SummaryLayout>
                  <AirportTransferProductSummary {...airportTransferProductSummaryProps} />
                </SummaryLayout>
              )}
            </div>
          </div>
        </PaymentInputContextProvider>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default PaymentInputPage;
