import {
  GetProductBySearchResultIdRequest,
  GetProductBySearchResultIdResponse,
  SearchItem,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import React, { useCallback, useReducer } from 'react';

import { yiluEnv } from 'utils';

import { CurrentProductContext, CurrentProductState } from './context';

const initialState: CurrentProductState = {
  data: null,
  loading: false,
  error: null,
};

const enum CurrentProductDispatchAction {
  LOAD_CURRENT_PRODUCT_PENDING = 'LOAD_CURRENT_PRODUCT_PENDING',
  LOAD_CURRENT_PRODUCT_SUCCESS = 'LOAD_CURRENT_PRODUCT_SUCCESS',
  LOAD_CURRENT_PRODUCT_FAILED = 'LOAD_CURRENT_PRODUCT_FAILED',
}

// Define action types
type Action =
  | { type: CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_PENDING }
  | { type: CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_SUCCESS; payload: SearchItem }
  | { type: CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_FAILED; payload: Error };

// Create a reducer
const reducer = (state: CurrentProductState, action: Action): CurrentProductState => {
  switch (action.type) {
    case CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_PENDING:
      return { ...state, loading: true, error: null };
    case CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const CurrentProductContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setCurrentProduct = useCallback((data: SearchItem) => {
    dispatch({ type: CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_SUCCESS, payload: data });
  }, []);

  const loadCurrentProduct = useCallback(async (searchResultID: string) => {
    const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID } = yiluEnv.getVariables();
    dispatch({ type: CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_PENDING });
    try {
      const response = await axios.get<GetProductBySearchResultIdResponse>(
        `${YILU_AMP_BACKEND_URL}/product/v1/products/search-results/${searchResultID}`,
        {
          params: {
            storeId: YILU_AMP_STORE_ID,
          } as GetProductBySearchResultIdRequest['parameters']['query'],
        },
      );
      const body = response.data;
      if (!body) {
        throw new Error(`Missing data for search result: ${searchResultID}`);
      }
      dispatch({ type: CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_SUCCESS, payload: body });
    } catch (error) {
      dispatch({
        type: CurrentProductDispatchAction.LOAD_CURRENT_PRODUCT_FAILED,
        payload: error as Error,
      });
    }
  }, []);

  return (
    <CurrentProductContext.Provider value={{ ...state, loadCurrentProduct, setCurrentProduct }}>
      {children}
    </CurrentProductContext.Provider>
  );
};
