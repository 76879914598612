import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { SearchItem } from '@yiluhub/yilu-amp-types';
import React, { useCallback, useReducer } from 'react';

import { fetchLoungeProduct } from 'modules/lounges/utils/fetchLoungeProduct';

import { LoungeProductContext, LoungeProductState } from './context';

const initialState: LoungeProductState = {
  data: null,
  loading: false,
  error: null,
};

const enum LoungeProductDispatchAction {
  LOAD_LOUNGE_PRODUCT_PENDING = 'LOAD_LOUNGE_PRODUCT_PENDING',
  LOAD_LOUNGE_PRODUCT_SUCCESS = 'LOAD_LOUNGE_PRODUCT_SUCCESS',
  LOAD_LOUNGE_PRODUCT_FAILED = 'LOAD_LOUNGE_PRODUCT_FAILED',
}

// Define action types
type Action =
  | { type: LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_PENDING }
  | { type: LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_SUCCESS; payload: SearchItem }
  | { type: LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_FAILED; payload: Error };

// Create a reducer
const reducer = (state: LoungeProductState, action: Action): LoungeProductState => {
  switch (action.type) {
    case LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_PENDING:
      return { ...state, loading: true, error: null };
    case LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const LoungeProductContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const loadLoungeProduct = useCallback(
    async (
      productId: string,
      locale: string,
      travellersCount?: { adults?: number; children?: number; infants?: number },
      entryDate?: string,
      airportCoordinates?: ElementsSDK.Coordinates,
    ) => {
      dispatch({ type: LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_PENDING });
      try {
        const body = await fetchLoungeProduct(
          productId,
          locale,
          travellersCount,
          entryDate,
          airportCoordinates,
        );
        dispatch({ type: LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_SUCCESS, payload: body });
      } catch (error) {
        dispatch({
          type: LoungeProductDispatchAction.LOAD_LOUNGE_PRODUCT_FAILED,
          payload: error as Error,
        });
      }
    },
    [],
  );

  return (
    <LoungeProductContext.Provider value={{ ...state, loadLoungeProduct }}>
      {children}
    </LoungeProductContext.Provider>
  );
};
