import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import React from 'react';

import './styles.scss';

type PageHeadingProps = {
  text: string;
};

export const PageHeading: React.FC<PageHeadingProps> = ({ text }) => (
  <ElementsSDK.Typography variant="h1" className="page-heading-text">
    {text}
  </ElementsSDK.Typography>
);
