import { useEffect } from 'react';

import { useCurrentProductContext } from 'context';

/**
 * Use current product.
 *
 * @param searchResultId - search result identifier
 */
export function useCurrentProduct(searchResultId: string | undefined) {
  const { data, loading, error, loadCurrentProduct } = useCurrentProductContext();

  useEffect(() => {
    if (data || !searchResultId) {
      return;
    }

    loadCurrentProduct(searchResultId);
  }, [data, searchResultId, loadCurrentProduct]);

  return {
    currentProduct: data && data.id === searchResultId ? data : null,
    isCurrentProductLoading: loading,
    currentProductError: error,
  };
}
