import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { DateFormat } from '@yiluhub/ui-utilities';
import {
  GetLoungeProductByLoungeIdRequest,
  GetLoungeProductByLoungeIdResponse,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import dayjs from 'dayjs';
import tzlookup from 'tz-lookup';

import { yiluEnv } from 'utils';

export async function fetchLoungeProduct(
  productId: string,
  locale: string,
  travellersCount?: { adults?: number; children?: number; infants?: number },
  entryDate?: string,
  airportCoordinates?: ElementsSDK.Coordinates,
): Promise<GetLoungeProductByLoungeIdResponse> {
  const { YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, YILU_AMP_LOUNGE_CATALOG_ID } =
    yiluEnv.getVariables();

  const passengerCount =
    (travellersCount?.adults || 1) +
    (travellersCount?.children || 0) +
    (travellersCount?.infants || 0);

  const timezone =
    airportCoordinates && airportCoordinates.latitude && airportCoordinates.longitude
      ? tzlookup(airportCoordinates.latitude, airportCoordinates.longitude)
      : undefined;

  const spacetimeDate = entryDate
    ? dayjs(entryDate).tz(timezone, true).utc().format(DateFormat.SHORT_DATE_WITH_FULL_TIME_Z)
    : undefined;

  const response = await axios.get<GetLoungeProductByLoungeIdResponse>(
    `${YILU_AMP_BACKEND_URL}/product/v1/products/lounge/${productId}`,
    {
      params: {
        catalogId: YILU_AMP_LOUNGE_CATALOG_ID,
        storeId: YILU_AMP_STORE_ID,
        numberOfTravelers: passengerCount,
        adults: travellersCount?.adults || 1,
        children: travellersCount?.children || 0,
        infants: travellersCount?.infants || 0,
        startAt: spacetimeDate,
        locale,
      } as GetLoungeProductByLoungeIdRequest['parameters']['query'] & {
        adults: number;
        children?: number;
        infants?: number;
      },
    },
  );

  if (!response.data) {
    throw new Error(`Missing data for search result: ${productId}`);
  }

  return response.data;
}
