import { SearchItem } from '@yiluhub/yilu-amp-types';
import { createContext } from 'react';

export interface CurrentProductState {
  data: SearchItem | null;
  loading: boolean;
  error: Error | null;
}

export interface CurrentProductContextType extends CurrentProductState {
  loadCurrentProduct: (searchResultID: string) => void;
  setCurrentProduct: (currentProduct: SearchItem) => void;
}

export const CurrentProductContext = createContext<CurrentProductContextType | undefined>(
  undefined,
);
