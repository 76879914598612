import React from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentInputContextProvider } from 'context';

import { PageHeading } from 'components';
import { SummaryLayout } from 'components/Layout';
import { PaymentInformation } from 'components/PaymentInformation';
import ProgressBar from 'components/ProgressBar';

import { ExperienceProductSummary } from 'modules/experiences/components';

import { useExperiencePaymentInputPage } from './hooks';
import './styles.scss';

const PaymentInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { paymentInformationFormProps, experienceProductSummaryProps, isPageReady } =
    useExperiencePaymentInputPage();

  return (
    <div className="experiences-pip">
      {isPageReady && (
        <PaymentInputContextProvider>
          <div className="form-container-wrapper">
            <div className="form-container container">
              <div className="content">
                <ProgressBar />
                <PageHeading text={t('Header - Payment')} />
                <PaymentInformation {...paymentInformationFormProps} />
              </div>
              <SummaryLayout>
                <ExperienceProductSummary {...experienceProductSummaryProps} />
              </SummaryLayout>
            </div>
          </div>
        </PaymentInputContextProvider>
      )}
    </div>
  );
};

export default PaymentInputPage;
