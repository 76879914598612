/**
 * # Use Redirect On Lounge Not Found
 *
 *
 */
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getURLSearchQuery } from 'utils/paramConverters';

import routes from 'router/routes';

import { useCurrentProductContext } from 'context';

import { useLoungeProductContext } from '../context';
import { getLoungeSearchInputPageQueryParams } from '../utils/query-params';

/**
 * useRedirectOnLoungeNotFound
 *
 * @param loungeId - lounge product identifier
 * @param searchResultID - search result identifier
 */
export function useRedirectOnLoungeNotFound(loungeId?: string, searchResultID?: string) {
  const location = useLocation();
  const navigate = useNavigate();
  const { error: currentProductError } = useCurrentProductContext();
  const { error: loungeProductError } = useLoungeProductContext();

  const searchInputPageQueryParams = useMemo(() => {
    return getLoungeSearchInputPageQueryParams(location);
  }, [location]);

  useEffect(() => {
    if (
      (loungeProductError && loungeId) ||
      (currentProductError && searchResultID) ||
      (!loungeId && !searchResultID)
    ) {
      navigate(
        {
          pathname: routes.LOUNGES_SEARCH,
          search: getURLSearchQuery(searchInputPageQueryParams),
        },
        { replace: true },
      );
    }
  }, [
    loungeProductError,
    currentProductError,
    loungeId,
    searchResultID,
    navigate,
    searchInputPageQueryParams,
  ]);
}
