import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeading } from 'components';
import { SummaryLayout } from 'components/Layout';
import ProgressBar from 'components/ProgressBar';
import { TravellerInformationForm } from 'components/TravellerInformationForm';

import { ExperienceProductSummary } from 'modules/experiences/components';

import { useExperienceTravellerInputPage } from './hooks';
import './styles.scss';

const ExperienceTravellerInputPage: React.FC = () => {
  const { t } = useTranslation();
  const { travellerInformationFormProps, experienceProductSummaryProps, isPageReady } =
    useExperienceTravellerInputPage();

  if (!isPageReady) return null;

  return (
    <div className="experiences-tip">
      <div className="form-container-wrapper">
        <div className="form-container container">
          <div className="content">
            <ProgressBar />
            <PageHeading text={t('Header - Personal Info')} />
            <TravellerInformationForm {...travellerInformationFormProps} />
          </div>
          <SummaryLayout>
            <ExperienceProductSummary {...experienceProductSummaryProps} />
          </SummaryLayout>
        </div>
      </div>
    </div>
  );
};

export default ExperienceTravellerInputPage;
