import { UiUtilities } from '@yiluhub/ui-sdk-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeading } from 'components';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import ProgressBar from 'components/ProgressBar';
import { TravellerInformationForm } from 'components/TravellerInformationForm';

import { RideInteractiveProductSummary } from 'modules/rides/components';

import { useTravellerInputPage } from './hooks';
import './styles.scss';

const TravellerInputPage: React.FC = () => {
  const { isPageReady, travellerInformationFormProps, rideProductInteractiveSummaryProps } =
    useTravellerInputPage();
  const isDesktop = UiUtilities.useMediaMatch('--viewport-l');
  const { t } = useTranslation();

  return (
    <div className="rides-tip">
      {isPageReady ? (
        <div className="form-container-wrapper">
          <div className="form-container container">
            <div className="content">
              <ProgressBar detailsStepLabel={t('rides.detailsStepLabel')} />
              <PageHeading text={t('Header - Personal Info')} />
              {!isDesktop && rideProductInteractiveSummaryProps && (
                <div className="content--mobile">
                  <RideInteractiveProductSummary {...rideProductInteractiveSummaryProps} />
                </div>
              )}
              {travellerInformationFormProps && (
                <TravellerInformationForm {...travellerInformationFormProps} />
              )}
            </div>
            {isDesktop && rideProductInteractiveSummaryProps && (
              <div className="content-interactive-summary--desktop">
                <RideInteractiveProductSummary {...rideProductInteractiveSummaryProps} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default TravellerInputPage;
