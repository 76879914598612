import { ElementsSDK } from '@yiluhub/ui-sdk-react';
import { formatCurrency, useIsDesktop } from '@yiluhub/ui-utilities';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { sendGAEvent } from 'utils/tracking';

import { PageHeading } from 'components';
import { SummaryLayout } from 'components/Layout';
import InteractiveSummaryLayout from 'components/Layout/InteractiveSummaryLayout';
import { LoadingPageAnimation } from 'components/LoadingPageAnimation';
import ProgressBar from 'components/ProgressBar';

import {
  AirportTransferProductDetails,
  AirportTransferProductSummary,
  AirportTransferSummaryBox,
  FlexbilityBanner,
} from 'modules/airport-transfer/components';

import { useProductDetailsPage } from './hooks';
import './styles.scss';

const ProductDetailsPage: React.FC = () => {
  const {
    isPageReady,
    productPriceModalProps,
    airportTransferProductDetailsProps,
    airportTransferProductSummaryProps,
    getTranslationForHeadingAndSubheading,
  } = useProductDetailsPage();
  const { isDesktop } = useIsDesktop();
  const { t } = useTranslation();
  const bvgSubheading = getTranslationForHeadingAndSubheading();
  useEffect(() => {
    if (!isPageReady) {
      return;
    }

    sendGAEvent({
      event: 'page_loaded',
      category: `airport-transfer-pdp`,
      label: `airport_transfer_pdp_loaded`,
    });
  }, [isPageReady]);

  const { isModalVisible, onCancel, onPriceChangeConfirm, newPrice, oldPrice, currency } =
    productPriceModalProps;

  const showHexBanner =
    isPageReady && airportTransferProductSummaryProps?.carrierName === 'Heathrow Express';
  const showCatBanner =
    isPageReady && airportTransferProductSummaryProps?.carrierName === 'City Airport Train';
  const showBVGBanner = isPageReady && airportTransferProductSummaryProps?.carrierName === 'BVG';
  return (
    <div className="airport-transfer-pdp">
      {isPageReady ? (
        <div className="form-container-wrapper">
          <div className="container form-container">
            <div className="content">
              <ProgressBar />
              {showHexBanner && (
                <FlexbilityBanner
                  heading={t('hexBanner.heading')}
                  subHeading={t('hexBanner.subheading')}
                />
              )}
              {showCatBanner && (
                <FlexbilityBanner
                  heading={t('catBanner.heading')}
                  subHeading={t('catBanner.subheading')}
                />
              )}
              {showBVGBanner && (
                <FlexbilityBanner heading={t('bvgBanner.heading')} subHeading={t(bvgSubheading)} />
              )}
              {airportTransferProductSummaryProps && !isDesktop && (
                <InteractiveSummaryLayout>
                  <AirportTransferSummaryBox {...airportTransferProductSummaryProps} />
                </InteractiveSummaryLayout>
              )}
              <PageHeading text={t('Header - Details')} />
              {airportTransferProductDetailsProps && (
                <AirportTransferProductDetails {...airportTransferProductDetailsProps} />
              )}
            </div>
            {airportTransferProductSummaryProps && (
              <SummaryLayout>
                <AirportTransferProductSummary {...airportTransferProductSummaryProps} />
              </SummaryLayout>
            )}
            <ElementsSDK.Modal
              isContentCentered
              size={isDesktop ? ElementsSDK.ModalSize.MEDIUM : ElementsSDK.ModalSize.SMALL}
              isVisible={isModalVisible}
              Icon={ElementsSDK.Icon.Warning}
              onConfirm={onPriceChangeConfirm}
              onCancel={onCancel}
              title={t('airporttransfer.pdp.price_change_heading.text')}
              confirmText={t('airporttransfer.pdp.continue_with_price.text', {
                newPrice: formatCurrency(newPrice, currency),
              })}
              cancelText={t('airporttransfer.pdp.cancel.text')}
              className={clsx('warning-modal', { 'warning-modal--mobile': !isDesktop })}
            >
              <ElementsSDK.Typography variant="p1">
                {t('airporttransfer.pdp.price_change.text', {
                  newPrice: formatCurrency(newPrice, currency),
                  oldPrice: formatCurrency(oldPrice, currency),
                })}
              </ElementsSDK.Typography>
            </ElementsSDK.Modal>
          </div>
        </div>
      ) : (
        <LoadingPageAnimation />
      )}
    </div>
  );
};

export default ProductDetailsPage;
