import React from 'react';
import { YiluAssetPathOverrides, YiluFeatures, YiluOverrides } from 'types';

import Router from 'router';

import {
  ComponentOverridesProvider,
  EnterprisePartnerConfigurationContextProvider,
  UserProfileContextProvider,
} from 'context';

import ErrorBoundary from './ErrorBoundary';

type AppProps = {
  features: Partial<YiluFeatures>;
  overrides: Partial<YiluOverrides>;
  defaultPath?: string;
  assetPathOverrides?: Partial<YiluAssetPathOverrides>;
};

const App: React.FC<AppProps> = ({ features, overrides, defaultPath, assetPathOverrides }) => (
  <ErrorBoundary>
    <EnterprisePartnerConfigurationContextProvider
      features={features}
      assetPathOverrides={assetPathOverrides}
    >
      <UserProfileContextProvider>
        <ComponentOverridesProvider overrides={overrides}>
          <Router initialPath={defaultPath} />
        </ComponentOverridesProvider>
      </UserProfileContextProvider>
    </EnterprisePartnerConfigurationContextProvider>
  </ErrorBoundary>
);

export default App;
