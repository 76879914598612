import { DateFormat } from '@yiluhub/ui-utilities';
import {
  GetProductByRefinedSearchRequest,
  GetProductByRefinedSearchResponse,
} from '@yiluhub/yilu-amp-types';
import axios from 'axios';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { yiluEnv } from 'utils';

import { useCurrentProductContext } from 'context';

const useUpdateAmpSearchResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { YILU_AMP_STORE_ID, YILU_AMP_BACKEND_URL } = yiluEnv.getVariables();
  const { setCurrentProduct } = useCurrentProductContext();

  return useCallback(
    async (
      searchResultId: string,
      travellersCount: {
        adults: number;
        children?: number;
        infants?: number;
      },
      newDate: string,
    ) => {
      try {
        const response = await axios.get<GetProductByRefinedSearchResponse>(
          `${YILU_AMP_BACKEND_URL}/product/v1/products/search-results/${searchResultId}/refined-search`,
          {
            params: {
              storeId: YILU_AMP_STORE_ID,
              startAt: dayjs(newDate).utc().format(DateFormat.SHORT_DATE_WITH_FULL_TIME_Z),
              numberOfTravelers:
                travellersCount.adults +
                (travellersCount.children || 0) +
                (travellersCount.infants || 0),
              adults: travellersCount.adults,
              children: travellersCount.children || 0,
              infants: travellersCount.infants || 0,
            } as GetProductByRefinedSearchRequest['parameters']['query'] & {
              adults: number;
              children?: number;
              infants?: number;
            },
          },
        );
        const body = response.data;

        const params = new URLSearchParams(location.search);
        params.set('searchResultID', body.id || '');
        setCurrentProduct(body);
        navigate({ ...location, search: params.toString() }, { replace: true });

        return body;
      } catch (error) {
        console.error('Error fetching refined search results', error);
        throw error;
      }
    },
    [YILU_AMP_BACKEND_URL, YILU_AMP_STORE_ID, location, setCurrentProduct, navigate],
  );
};

export default useUpdateAmpSearchResult;
